import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";

import Layout from "../components/layout";
import SEO from "../components/seo";

const RealtorsAndFoorPlans = () => {
  const data = useStaticQuery(graphql`
    query {
      balconies: file(relativePath: { eq: "balconies-1031241_1920.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      papers: file(relativePath: { eq: "newspaper-943004_1920.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tooDetailed: file(
        relativePath: { eq: "electrical-planning-3536767_1920.jpg" }
      ) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      plan: file(relativePath: { eq: "floor_plan_v3.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);

  return (
    <Layout maxWidth={600}>
      <SEO 
      title="Realtors and floor plans: a story of a hard love." 
      description="Real estate agents and photographers do not always use floor plans in their listings. 
                  Why is that, and how can this be changed?"
      // image={data.papers.childImageSharp}
      />
      <div style={{ padding: "0px 1em" }}>
        <h1>Realtors and floor plans: a story of a hard love.</h1>
        <Img
          style={{ marginBottom: "1.45rem" }}
          {...data.balconies.childImageSharp}
        />

        <p>
          Back in a day, when one wanted to buy a new home, they’d need to sort
          through columns of plain text listing properties only sparsely
          followed by a pixelated black and white picture. A brief description
          was enough to get the basic facts straight, like total size or the
          number of rooms. As you’d think, visiting a prospective future home
          felt more like going on a blind date, rather than just confirming what
          you already know about that property.
        </p>

        <p>
          Then, the internet came. Newspapers lost a source of income (and
          gained quite a few pages to print the evening news on) and property
          portals became the king. Now, it was possible to look for a flat in
          the exact area one was interested in, or reject houses which were
          obviously too large. Photo galleries (which were taken using
          old-school digital cameras rather than smartphones) killed the blind
          date vibe in the real estate.
        </p>
        <Img
          style={{ marginBottom: "1.45rem" }}
          {...data.papers.childImageSharp}
        />
        <p>
          However, while most of the modern listings have lost their enigmatic
          thrill, the presentation quality still varies wildly. From agency to
          agency, they range anywhere from crude interior pictures to
          full-featured VR tours. Quite obviously, some of the realtors have
          introduced standards to ensure listings are consistent within their
          domain. In this way, the customers are attracted back to the websites
          that match their expected quality.
        </p>
        <p>
          One of the key points of a property listing is its floor plan. Hence,
          it is expected that each will contain at least a rough sketch of the
          floor layout, so that the customer does not have to take the wild
          guess from the pictures. By providing this experience the client is
          likely to go back and rely on the service, so that they can view only
          those apartments he is likely to buy.
        </p>
        <p>
          Unfortunately, most of the current listings do not have this feature.
          Does anyone have a good reason not to include the floor plan? While
          sounding a bit absurd, there are actually some subtle reasons not to.
        </p>
        <p>
          The first most common reason is the availability. While all the
          apartments had their plans created at some point, those documents are
          often available to the building administration. It takes precious time
          to get them, which is the only resource one cannot buy.
        </p>
        <p>
          Suppose the prospective property seller does have the floor plan. In
          this case, it might be outdated or worn out to be displayed near
          airbrushed photos of the interior. And in an even rarer case of a new
          building, where the plans can be crisply sharp, it might be impossible
          to fit a detailed architectural plan into the listing gallery. It
          might be not easy to understand for a layperson — those plans commonly
          include a deluge of unnecessary elements.
        </p>
        <Img
          style={{ marginBottom: "1.45rem" }}
          {...data.tooDetailed.childImageSharp}
        />
        <p>
          This leads to a lot of half-baked solutions. Floor plans that are
          re-drawn basing on the original, less aesthetic ones. Listings where
          only a fraction of properties do have a floor layout. Drawings which
          do not look professional or are drawn without the scale. Professional
          surveys which have great quality, but take much time and effort to
          complete.
        </p>

        <Img
          style={{ marginBottom: "1.45rem" }}
          {...data.plan.childImageSharp}
        />
        <p>
          At PanoRoom, we think that this situation can be radically changed.
          Recently smartphones got aware of the space, and are able to locate
          themselves with centimetre-level accuracy (a mere inches). We have
          successfully created plans of entire apartments in 10–15 minutes by
          using Augmented Reality solution. Also, we offer a level of accuracy
          which was not precedented: we consistently provide plans which
          correspond to the actual space.
        </p>

        <p>
          We are currently working hard to change the way the property market
          works. While so far hard to get, floor layouts will eventually become
          a commodity. The ones who are most likely to profit from this change
          are the early adopters, who recognise the incoming opportunity in
          advance.
        </p>

        <p>
          Recent advances in technology have changed the way we buy our future
          homes, and smartphones with Augmented Reality will bring it to the
          next level. While most of the industry still sticks to the
          no-floor-plan standard, we as a company want to change this. We are
          looking forward to you joining us this pursuit!
        </p>
      </div>
    </Layout>
  );
};

export default RealtorsAndFoorPlans;
